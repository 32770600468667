import React from 'react';
import './HistoryPage.css';
import HomeButton from "../../Components/HomeButton/HomeButton";
import Accordion from "../../Components/Accordion/Accordion";

const season1 = {
  Title: "Season 1",
  BaseUrl: "/scoreboard/season-1/",
  StartFrom: 1,
  EndAt: 62,
};

const season0 = {
  Title: "Season 0",
  BaseUrl: "/scoreboard/season-0/",
  StartFrom: 14,
  EndAt: 100,
};

const specialSeason = {
  Title: "Special Battles",
  BaseUrl: "/scoreboard/specials/special",
  StartFrom: 1,
  EndAt: 3,
};

let season1Items = [];
for (let i = season1.EndAt; i >= season1.StartFrom; i--) {
  season1Items.push({
    DisplayName: `Battle ${i}`,
    Link: `${season1.BaseUrl}${i}`
  });
}

let season0Items = [];
for (let i = season0.EndAt; i >= season0.StartFrom; i--) {
  season0Items.push({
    DisplayName: `Battle ${i}`,
    Link: `${season0.BaseUrl}${i}`
  });
}

let specialItems = [];
for (let i = specialSeason.EndAt; i >= specialSeason.StartFrom; i--) {
  specialItems.push({
    DisplayName: `Battle ${i}`,
    Link: `${specialSeason.BaseUrl}${i}`
  });
}

const HistoryPage = () => {
  return (
    <main className={"historyContainer"}>
      <h1 style={{margin: "8px 0"}}>Battle History</h1>
      <HomeButton/>
      <Accordion title="Season 1" items={season1Items}/>
      <Accordion title="Season 0" items={season0Items}/>
      <Accordion title="Special Battles" items={specialItems}/>
    </main>
  );
};

export default HistoryPage;